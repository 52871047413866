import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import Pulse from "@ubisend/pulse-boost";

import logo from "./logo.png";

Pulse.render({
  partner: {
    logo,
    url: "https://convoboss.com/",
    policy_name: 'By creating my account I agree to the Acceptable Use Policy.',
    policy_url: 'https://s3.eu-west-2.amazonaws.com/ubisend.legals/Acceptable-Use-Policy_Authorised-Users.pdf'
  }
});
